import { Routes, Route } from 'react-router-dom';
import BlogComponent from '../components/Blog';
import BlogPost from '../components/BlogPost';
import DonateLayout from '../components/DonateLayout';

interface BlogPageProps {
  isDarkMode?: boolean;
}

const BlogPage: React.FC<BlogPageProps> = ({ isDarkMode = false }) => {
  return (
    <DonateLayout isDarkMode={isDarkMode}>
      <Routes>
        <Route path="/" element={<BlogComponent />} />
        <Route path=":id" element={<BlogPost />} />
      </Routes>
    </DonateLayout>
  );
};

export default BlogPage;
