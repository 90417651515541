import { useState, useEffect } from 'react';
import Chat from './components/Chat';
import Donation from './components/Donation';
import Header from './components/Header';
import Footer from './components/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DonatePage from './pages/Donate';
import BlogPage from './pages/Blog';
import DonateLayout from './components/DonateLayout';
import { HelmetProvider } from 'react-helmet-async';

export default function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [jesusImage, setJesusImage] = useState('/JesusChrist.webp');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const personality = params.get('personality');
    if (personality === 'snarky') {
      setJesusImage('/jesusObiWan.jpg');
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <div className={`min-h-screen flex flex-col ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-50'}`}>
              <Header isDarkMode={isDarkMode} />
              <div className="flex-1 flex flex-col md:flex-row container mx-auto gap-4 p-4">
                {/* Left Sidebar */}
                <aside className={`w-full md:w-80 flex flex-col gap-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg shadow-lg `}>
                  {/* Profile/Image Section */}
                  <div className="aspect-square w-full rounded-lg overflow-hidden">
                    <img 
                      src={jesusImage}
                      alt="Jesus" 
                      className="w-full h-full object-cover object-top"
                    />
                  </div>

                  {/* Dark Mode Toggle */}
                  <button
                    onClick={() => setIsDarkMode(!isDarkMode)}
                    className={`mb-4 px-4 py-2 rounded-lg flex items-center justify-center gap-2 ${
                      isDarkMode 
                        ? 'bg-gray-700 text-white hover:bg-gray-600' 
                        : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                    } transition-colors`}
                  >
                    {isDarkMode ? '☀️ Light Mode' : '🌙 Dark Mode'}
                  </button>

                  {/* Donation Section - Only visible on desktop */}
                  <div className="mt-auto hidden md:block w-full">
                    <Donation isDarkMode={isDarkMode} />
                  </div>
                </aside>

                {/* Chat Section */}
                <main className="flex-grow">
                  <Chat isDarkMode={isDarkMode} />
                </main>

                {/* Donation Section - Only visible on mobile */}
                <div className="w-full md:hidden mb-16">
                  <Donation isDarkMode={isDarkMode} />
                </div>
              </div>
              <Footer isDarkMode={isDarkMode} />
            </div>
          } />
          <Route path="/donate" element={
            <DonateLayout isDarkMode={isDarkMode}>
              <DonatePage />
            </DonateLayout>
          } />
          <Route path="/blog/*" element={
            <BlogPage isDarkMode={isDarkMode} />
          } />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}
