import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';

interface Article {
  id: number;
  title: string;
  content: string;
  date: string;
  views: number;
}

// Regular expression to match Bible citations
const bibleRegex = /\(([\w\s]+\s\d+:\d+(?:-\d+)?)\)/g;

const BlogPost: React.FC = () => {
  const [article, setArticle] = useState<Article | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch('/articles.json');
        const data = await response.json();
        const foundArticle = data.articles.find((a: Article) => a.id === Number(id));
        setArticle(foundArticle || null);
      } catch (error) {
        console.error('Error fetching article:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Loading...
            </h1>
          </div>
        </div>
      </div>
    );
  }

  if (!article) {
    return (
      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Article not found
            </h1>
          </div>
        </div>
      </div>
    );
  }

  // Get the first paragraph as description (limited to 160 characters)
  const description = article.content
    .split('\n')[0]
    .replace(bibleRegex, '')
    .slice(0, 160)
    .trim();

  // Function to convert Bible citations into links
  const renderContentWithBibleLinks = (content: string) => {
    const parts = content.split(bibleRegex);
    return parts.map((part, index) => {
      if (index % 2 === 1) { // This is a Bible citation
        const citation = part.trim();
        const bibleGatewayUrl = `https://www.biblegateway.com/passage/?search=${encodeURIComponent(citation)}&version=NIV`;
        return (
          <a
            key={index}
            href={bibleGatewayUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800"
          >
            ({citation})
          </a>
        );
      }
      return part;
    });
  };

  // Format the date for schema markup
  const formattedDate = new Date(article.date).toISOString();
  const canonicalUrl = `https://speakwithjesus.org/blog/${article.id}`;

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{`${article.title} | Speak with Jesus`}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalUrl} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://speakwithjesus.org/JesusChrist.webp" />
        <meta property="article:published_time" content={formattedDate} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://speakwithjesus.org/JesusChrist.webp" />

        {/* Schema.org markup for Google */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": canonicalUrl
            },
            "headline": article.title,
            "description": description,
            "image": "https://speakwithjesus.org/JesusChrist.webp",
            "datePublished": formattedDate,
            "dateModified": formattedDate,
            "author": {
              "@type": "Organization",
              "name": "Speak With Jesus",
              "url": "https://speakwithjesus.org"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Speak With Jesus",
              "url": "https://speakwithjesus.org",
              "logo": {
                "@type": "ImageObject",
                "url": "https://speakwithjesus.org/JesusChrist.webp"
              }
            }
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          {/* CTA Section */}
          <div className="bg-white p-6 rounded-lg shadow-lg mb-8">
            <div className="text-center">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Support Our Service</h2>
              <p className="text-gray-600 mb-6">Help us continue spreading the word of Jesus Christ and providing spiritual guidance</p>
              <div className="flex justify-center space-x-4">
                <Link
                  to="/donate"
                  className="inline-block bg-blue-600 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-700 transition duration-150 ease-in-out"
                >
                  Donate Now
                </Link>
                <Link
                  to="/"
                  className="inline-block bg-green-600 text-white px-6 py-3 rounded-md font-medium hover:bg-green-700 transition duration-150 ease-in-out"
                >
                  Chat with Jesus
                </Link>
              </div>
            </div>
          </div>

          <article className="bg-white p-6 rounded-lg shadow-lg" itemScope itemType="https://schema.org/BlogPosting">
            <header>
              <h1 itemProp="headline" className="text-3xl font-bold text-gray-900 mb-4">
                {article.title}
              </h1>
              <div className="text-sm text-gray-500 mb-6">
                <time itemProp="datePublished" dateTime={formattedDate}>
                  {format(new Date(article.date), 'MMMM d, yyyy')}
                </time>
              </div>
            </header>

            <div className="prose max-w-none" itemProp="articleBody">
              {article.content.split('\n').map((paragraph, index) => (
                paragraph.trim() && (
                  <p key={index} className="mb-4">
                    {renderContentWithBibleLinks(paragraph)}
                  </p>
                )
              ))}
            </div>

            <footer className="mt-6 text-sm text-gray-500">
              <meta itemProp="author" content="Speak With Jesus" />
            </footer>
          </article>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
