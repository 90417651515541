import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

interface Article {
  id: number;
  title: string;
  content: string;
  date: string;
  views: number;
}

const Blog: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('/articles.json');
        const data = await response.json();
        setArticles(data.articles.sort((a: Article, b: Article) => 
          new Date(b.date).getTime() - new Date(a.date).getTime()
        ));
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Loading...
            </h1>
          </div>
        </div>
      </div>
    );
  }

  const canonicalUrl = 'https://speakwithjesus.org/blog';
  const pageTitle = 'Speak With Jesus Blog | Speak with Jesus';
  const pageDescription = 'Explore deep dives into biblical stories, their meanings in modern context, and spiritual insights to strengthen your faith journey.';

  return (
    <>
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href={canonicalUrl} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content="https://speakwithjesus.org/JesusChrist.webp" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="https://speakwithjesus.org/JesusChrist.webp" />

        {/* Schema.org markup for Google */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "Speak With Jesus Blog",
            "description": pageDescription,
            "url": canonicalUrl,
            "publisher": {
              "@type": "Organization",
              "name": "Speak With Jesus",
              "url": "https://speakwithjesus.org",
              "logo": {
                "@type": "ImageObject",
                "url": "https://speakwithjesus.org/JesusChrist.webp"
              }
            },
            "blogPost": articles.map(article => ({
              "@type": "BlogPosting",
              "headline": article.title,
              "datePublished": new Date(article.date).toISOString(),
              "dateModified": new Date(article.date).toISOString(),
              "url": `https://speakwithjesus.org/blog/${article.id}`,
              "description": article.content.split('\n')[0].substring(0, 200),
              "author": {
                "@type": "Organization",
                "name": "Speak With Jesus"
              }
            }))
          })}
        </script>
      </Helmet>

      <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
        <main className="max-w-3xl mx-auto">
          <header className="text-center mb-12">
            <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Speak With Jesus Blog
            </h1>
            <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              Deep dives into biblical stories and their meanings in modern context
            </p>
          </header>

          <div className="mt-12 max-w-lg mx-auto grid gap-8" itemScope itemType="https://schema.org/Blog">
            {articles.map((article) => {
              const articleUrl = `/blog/${article.id}`;
              const excerpt = article.content.split('\n')[0].substring(0, 200);
              const formattedDate = format(new Date(article.date), 'MMMM d, yyyy');

              return (
                <article
                  key={article.id}
                  className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                  itemScope
                  itemType="https://schema.org/BlogPosting"
                >
                  <Link to={articleUrl} className="block" aria-label={`Read ${article.title}`}>
                    <meta itemProp="datePublished" content={new Date(article.date).toISOString()} />
                    <meta itemProp="author" content="Speak With Jesus" />
                    
                    <h2 className="text-xl font-semibold text-gray-900 mb-2 hover:text-blue-600" itemProp="headline">
                      {article.title}
                    </h2>
                    
                    <time dateTime={new Date(article.date).toISOString()} className="text-sm text-gray-500 mb-4 block">
                      {formattedDate}
                    </time>
                    
                    <div className="prose max-w-none text-gray-700" itemProp="description">
                      {excerpt}...
                    </div>
                    
                    <div className="mt-4 flex justify-between items-center">
                      <span className="text-sm text-gray-500">
                   
                      </span>
                      <span className="text-blue-600 hover:text-blue-800" aria-hidden="true">
                        Read more →
                      </span>
                    </div>
                  </Link>
                </article>
              );
            })}
          </div>
        </main>
      </div>
    </>
  );
};

export default Blog;
