import { useEffect } from 'react';

// Extend Window interface to include gtag_report_conversion
declare global {
  interface Window {
    gtag_report_conversion: (url?: string) => boolean;
  }
}

interface DonationProps {
  isDarkMode?: boolean;
  isDonatePage?: boolean;
}

export default function Donation({ isDarkMode, isDonatePage = false }: DonationProps) {
  // Add event listener when component mounts
  useEffect(() => {
    const buyButton = document.querySelector('stripe-buy-button');
    if (buyButton) {
      buyButton.addEventListener('click', () => {
        // Call the conversion tracking function
        window.gtag_report_conversion();
      });
    }

    // Cleanup listener when component unmounts
    return () => {
      const buyButton = document.querySelector('stripe-buy-button');
      if (buyButton) {
        buyButton.removeEventListener('click', () => {
          window.gtag_report_conversion();
        });
      }
    };
  }, []); // Empty dependency array means this runs once when component mounts

  return (
    <div className={`text-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
      {!isDonatePage && (
        <>
          <h2 className={`text-lg font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            Support This Service
          </h2>
          <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
            If you found this helpful, please consider a donation to help us keep this service running.
          </p>
        </>
      )}
      <div className="flex justify-center">
        <stripe-buy-button
          buy-button-id="buy_btn_1QW2jYDX9hAreI5R5mHEvydS"
          publishable-key="pk_live_51QW2JCDX9hAreI5RjtAoggCgF0BmkMsm8XYauRutLvIY4mnDK0gzZGCA72jShyuzrjfqrMhiwlDXKJ4s7Xidii1O00fgGgKgeW"
        >
        </stripe-buy-button>
      </div>
    </div>
  );
}
