import { useSearchParams } from 'react-router-dom';

interface FooterProps {
  isDarkMode?: boolean;
}

export default function Footer({ isDarkMode }: FooterProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleCopyrightClick = () => {
    if (searchParams.has('personality')) {
      searchParams.delete('personality');
    } else {
      searchParams.set('personality', 'snarky');
    }
    setSearchParams(searchParams);
    // Reload the page to apply the new personality
    window.location.reload();
  };

  return (
    <footer className={`${
      isDarkMode 
        ? 'bg-gray-800 text-gray-400 border-t border-gray-700' 
        : 'bg-white text-gray-500 shadow-inner'
    } p-4 text-center text-sm transition-colors mt-auto`}>
      <span onClick={handleCopyrightClick} style={{cursor: 'pointer'}}>
        &copy; {new Date().getFullYear()} speakwithjesus.org
      </span>
    </footer>
  );
}
