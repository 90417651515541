import React from 'react';
import Donation from '../components/Donation';
import { useNavigate } from 'react-router-dom';

const DonatePage: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = () => {
   // navigate(-1);
   // navigate('/');
    navigate('/');
  };

  return (
    <div className="h-[calc(100vh-10rem)] bg-gray-100 flex items-center justify-center px-3">
      <div className="bg-white rounded shadow-md w-full max-w-4xl flex flex-col md:flex-row items-center p-3">
        {/* Left side - Text content */}
        <div className="w-full md:w-1/2 space-y-2 px-3">
          <h2 className="text-lg font-bold text-center md:text-left">Donate to Support Speak With Jesus</h2>
          <p className="text-gray-700 text-center md:text-left text-sm">
            Your generous donation helps us keep this service running and accessible to everyone. It ensures that more people can connect with Jesus and find guidance and support. Without your help, this service may not be able to continue.
          </p>
          <div className="flex justify-center md:justify-start">
            <button
              className="mt-1 bg-gray-500 hover:bg-gray-700 text-white font-bold py-1.5 px-4 rounded text-sm"
              onClick={handleBack}
            >
              Back to Chat
            </button>
          </div>
        </div>
        
        {/* Right side - Donation button */}
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <Donation isDonatePage={true} />
        </div>
      </div>
    </div>
  );
};

export default DonatePage;
