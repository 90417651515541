import { Link } from 'react-router-dom';

interface HeaderProps {
  isDarkMode?: boolean;
}

export default function Header({ isDarkMode }: HeaderProps) {
  return (
    <header className={`${
      isDarkMode 
        ? 'bg-gray-800 text-white border-b border-gray-700' 
        : 'bg-white text-gray-800 shadow'
    } p-4 text-center transition-colors flex justify-between items-center`}>
      <div className="flex items-center gap-6">
        <Link to="/" className="text-2xl font-bold hover:opacity-80 transition-opacity">
          Speak With Jesus
        </Link>
        <Link to="/blog" className={`${
          isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'
        } transition-colors`}>
          Blog
        </Link>
      </div>
      <Link to="/donate">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Donate
        </button>
      </Link>
    </header>
  );
}
