import React from 'react';
import Header from './Header';
import Footer from './Footer';

interface DonateLayoutProps {
  children: React.ReactNode;
  isDarkMode: boolean;
}

const DonateLayout: React.FC<DonateLayoutProps> = ({ children, isDarkMode }) => {
  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? 'dark bg-gray-900' : 'bg-gray-50'}`}>
      <Header isDarkMode={isDarkMode} />
      <main className="flex-1 container mx-auto p-4">
        {children}
      </main>
      <Footer isDarkMode={isDarkMode} />
    </div>
  );
};

export default DonateLayout;
